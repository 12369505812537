



































import { Vue, Component } from 'vue-property-decorator'
import NotificationCards from '@/components/common/NotificationCards.vue'
import {
  notificationTypesTabs,
  notificationTypesTranslation
} from '@/config/types/notificationTypes'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import NotificationsAPI from '@/api/requests/notifications'
import { notificationsMapper } from '@/store/modules/notifications'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapGetters(['notifications'])
  },
  methods: {
    ...notificationsMapper.mapActions(['fetchBellNotifications'])
  }
})
@Component({ components: { NotificationCards } })
export default class Notifications extends Mappers {
  private tabs: any[] = []
  private noNotifications = false

  private created() {
    const ability = PermissionManager.notifications()
    if (ability.rules.length) {
      this.noNotifications = false
      for (const [type, title] of Object.entries(notificationTypesTabs)) {
        if (ability.can(permissions.viewNotificationTabs, type)) {
          this.tabs.push({
            title: title,
            name: notificationTypesTranslation[type],
            type: type
          })
        }
      }
    } else {
      this.noNotifications = true
    }
  }

  private async readAll() {
    await NotificationsAPI.markNotificationAsReadAll()
    await this.fetchBellNotifications()

    for (const notifications_ of Object.values(this.notifications)) {
      for (const notification of notifications_) {
        notification.isRead = true
      }
    }
  }
}
