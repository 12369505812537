















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import {
  LIMIT_PAGINATION_LIST,
  notificationTypes,
  notificationTypesTranslation,
  notificationTypesTabs
} from '@/config/types/notificationTypes'
import dateFormat from '@/config/default/dateFormat'
import { Notification } from '@/store/modules/notifications'
import { notificationsMapper } from '@/store/modules/notifications'
import NotificationsAPI from '@/api/requests/notifications'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapGetters(['notifications'])
  },
  methods: {
    ...notificationsMapper.mapActions([
      'fetchNotifications',
      'fetchBellNotifications'
    ])
  }
})
@Component
export default class NotificationCards extends Mappers {
  @Prop(String) readonly title!: string
  @Prop(String) readonly type!: notificationTypes
  private mapper = notificationTypesTranslation
  private notificationTypesTabs_ = notificationTypesTabs

  private disableButton = false
  private notificationsList: Notification[] = []

  private async created() {
    if (this.type) {
      await this.fetchNotifications({ offset: 0, type: this.type })
      Vue.set(this, 'notificationsList', this.notifications[this.type])
      if (this.notificationsList.length < LIMIT_PAGINATION_LIST) {
        this.disableButton = true
      }
    }
  }

  private getReadCardStyle(isDisabled: boolean) {
    return isDisabled ? 'notification-unread card-body' : 'card-body'
  }
  private getReadTextStyle(isDisabled: boolean) {
    return isDisabled
      ? 'disabled d-inline-block text-message'
      : 'd-inline-block text-message'
  }

  private getFormattedTime(timestamp: string) {
    return timestamp
      ? moment(timestamp)
          .locale('ru')
          .format(dateFormat.date.message)
      : ''
  }

  private async changeStatus(id: number) {
    const notification = this.notificationsList.find(n => n.id === id)
    if (notification) {
      if (notification.isRead) {
        await NotificationsAPI.markNotificationAsUnRead(id)
      } else {
        await NotificationsAPI.markNotificationAsRead(id)
      }

      await this.fetchBellNotifications()

      notification.isRead = !notification.isRead
    }
  }

  private async loadMore() {
    await this.fetchNotifications({ offset: 'next', type: this.type })
    const newNotificationsList = this.notifications[this.type]
    if (
      newNotificationsList.length % LIMIT_PAGINATION_LIST !== 0 ||
      newNotificationsList.length === this.notificationsList.length
    ) {
      this.disableButton = true
    }
    this.notificationsList = newNotificationsList
  }

  private async readAllType(type: notificationTypes) {
    await NotificationsAPI.markNotificationAsReadByType(type)
    this.fetchBellNotifications()
    for (const notification of this.notificationsList) {
      notification.isRead = true
    }
  }
}
